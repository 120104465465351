import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/reizdarm-ernahrung"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331 active-trail">
                      <a href="/diagnose-reizdarm/" className="active-trail">
                        Diagnose Reizdarm
                      </a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div id="block-menu-menu-reizdarm" className="block block-menu">
                  <h2>Reizdarm</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/diagnose-reizdarm/" title>
                          Diagnose Reizdarm (IBS)
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/behandlung-eines-reizdarms/" title>
                          Behandlung eines Reizdarms
                        </a>
                      </li>
                      <li className="last leaf active-trail">
                        <a
                          href="/reizdarm-ernahrung/"
                          title
                          className="active-trail active"
                        >
                          Ernährung bei Reizdarm
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-9" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{"font-size":"30px",
                     "line-height":"26px", "margin":"20px 0 10px 0", "font-family": "Open Sans, sans-serif"}}>
                        REZEPTE</h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_rezepte.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Eine ausgewogene Ernährung ist eine wichtige Voraussetzung für Gesundheit und Wohlbefinden. Hier finden Sie
                        Rezepte, die lecker und gesund sind.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a className="georgia arrow_01" href="/rezepte/">
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/reizdarm-ernahrung"
                      typeof="sioc:Item foaf:Document"
                      className="ds-2col-stacked node node-simple-page view-mode-full clearfix"
                    >
                      <div className="group-header">
                        <h1>Ernährung bei Reizdarm </h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/header_visuals_695x286_ernaehrung_reizdarm.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="firstrow">
                          <p className="subline">
                            Die richtige Ernährung bei Reizdarm{" "}
                          </p>
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <p>
                                Bei Reizdarm ist es wichtig, die individuell
                                unverträglichen Nahrungsmittel zu identifizieren
                                und zu meiden. Um leichter herauszufinden, auf
                                welche Nahrungsmittel Ihre Verdauung reagiert,
                                sollten Sie das eigene Ernährungsverhalten genau
                                beobachten und dieses in einem
                                Ernährungstagebuch dokumentieren.
                              </p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className="field field-name-field-body-right field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <p>
                                Auch wenn es keine spezielle "Reizdarm-Diät"
                                gibt, geht man davon aus, dass sich
                                fettarme&nbsp;Kost und nicht allzu stark
                                gewürzte Speisen positiv auf Ihr
                                Verdauungssystem auswirken.
                              </p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="std-site">
                        <div className="leftcol group-left"></div>
                        <div className="rightcol group-right"></div>
                      </div>
                      <div className="group-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="region region-special-block">
                <div id="block-block-8" className="block block-block">
                  <div className="content">
                    <h3>Welches IMODIUM® ist das Richtige für mich?</h3>
                    <p>
                      <img
                        alt
                        src="/sites/default/files/Imodium_Range_2_Produkte_699x275.jpg"
                        style={{
                          width: "699px",
                          height: "274px"
                        }}
                      />
                    </p>
                    <div className="nextbtn">
                      <a className="btn" href="/imodium-produkte/">
                        <span className="start">Produktübersicht</span>
                        <span className="end">&nbsp;</span>
                      </a>
                    </div>
                    <div className="clear">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf active-trail">
                    <a
                      href="/reizdarm-ernahrung/"
                      title
                      className="active-trail active"
                    >
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                   <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
